import { render, staticRenderFns } from "./ConfirmationApply.vue?vue&type=template&id=6b12fc50&lang=pug&"
import script from "./ConfirmationApply.vue?vue&type=script&lang=js&"
export * from "./ConfirmationApply.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/.pnpm/vue-loader@15.10.1_babel-core@7.0.0-bridge.0_cache-loader@4.1.0_css-loader@3.6.0_pug@3.0.2_vu_ootqulghporifofhfrfac4zhiu/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports