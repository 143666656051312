<template lang="pug">
v-container.fill-height(fluid)
  v-row(row justify="center" align="center")
    v-col.text-center(cols="6")
      div(v-if="in_progress")
        h4 {{ confirmation.message_in_progress }}
        br
        v-progress-circular(
          :indeterminate="true"
          color="primary"
        )
      template(v-else)
        div(v-if="status === 200")
          h3 {{ message }}
            span.group.pa-2
              v-icon(
                dark
                color="green"
                large
              ) mdi-check
        div(v-else)
          h3 {{ message }}
            span.group.pa-2
              v-icon(
                dark
                color="red"
                large
              ) mdi-alert-octagon
          p(v-if="submessage") {{ submessage }}
</template>

<script>
import { mapState } from "vuex";

import api from "@/api";
import { CONFIRMATIONS } from "@/confirmations";
import { getRouterLocationWhenLogged } from "@/router";

export default {
  props: {
    confirmationType: {
      type: String,
      required: true,
      validator: function (value) {
        return Object.keys(CONFIRMATIONS).indexOf(value) !== -1;
      },
    },
    // user or carrier editable ID
    relationId: {
      type: String,
      required: true,
    },
    // user or carrier editable token
    relationToken: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      // Messages are provided by the server
      message: "",
      submessage: "",
      status: null,
      in_progress: true,
    };
  },

  async mounted() {
    const url = this.confirmation.getUrl.apply(this);
    const response = await api.get(url);
    this.message = (response.data && response.data.message) || "";
    this.submessage = (response.data && response.data.submessage) || "";
    this.status = response.status;

    if (this.status === 200) {
      // Chain on another confirmation (carrier)
      if (response.data.confirmation) {
        setTimeout(() => {
          this.$router.push({
            name: "confirmations__waiting",
            params: {
              message: response.data.confirmation.message,
            },
          });
        }, 2000);
      } else {
        const routerLocation = getRouterLocationWhenLogged(this.user);
        if (routerLocation) {
          // Display message for a short duration and go to carrier list
          setTimeout(() => {
            this.$router.push(routerLocation);
          }, 3000);
        }
      }
    }

    this.in_progress = false;
  },

  computed: {
    confirmation() {
      return CONFIRMATIONS[this.confirmationType];
    },
    ...mapState(["user"]),
  },
};
</script>
